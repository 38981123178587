
input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}

input[type=date]::-webkit-clear-button {
    -webkit-appearance: none;
    display: none;
}

.resultsDD {
    padding: 3px;
    border: solid orange 1.5px;
    font-family: 'nunito_sansregular';
    font-size: 15px;
}

.resultsDD option {
    padding: 3px;
}
