@import url(/assets/fonts/stylesheet.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img.imageLoader {
  position: fixed;
  top: 40%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 50px;
}



::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

/*-----------Custom CSS---------------*/
* {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  min-height: 100%;
  font-size: 16px;
  color: #282828;
}
html {
  background: transparent;
  box-sizing: border-box;
  position: relative;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  color: #282828;
  font-family: "nunito_sansregular", Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
  overflow-x: hidden;
  position: relative;
}
a {
  color: #de1245;
  text-decoration: none;
}
a:hover {
  color: black;
}
p {
  color: #686868;
}
.page-heading {
  font-size: 1.5rem;
}
.visible-xs {
  display: none !important;
}

.btnUpload{
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 2px;
  background-color: #f99919;
  transition: all 0.3s; 
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 2px;
  color: #fff;
  background-color: #f99919;
  transition: all 0.3s;
}

.btn:disabled
{
  cursor:not-allowed;
}

.marR15
{
  margin-right: 15px ;
}

.floatR
{
  float: right ;
}


.btn:hover {
  color: rgba(255, 255, 255, 0.8);
}
.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
  font-size: 14px;
}
.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
  color: #a94442;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.server-error {
  text-align: center;
  color: #a94442;
  padding: 0;
  font-size: 20px;
  margin-bottom: 15px;
}
header {
  background-color: #212121;
  height: 56px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  text-align: right;
  padding-right: 15px;
}
header .logged-in {
  color: #fff;
}
.logged-in i {
  font-size: 20px;
  vertical-align: middle;
}
.logout-btn {
  padding: 6px 12px 6px 12px;
  background-color: #f99919;
  display: inline-block;
  color: #fff;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 14px;
  margin: 9px 0 8px 8px;
}
.logo-wrapper {
  margin: 0;
  height: 55px;
  padding-right: 10px;
  position: relative;
  top: 12px;
  width: 162px;
  float: left;
  outline: none !important;
}
.logo-wrapper .logo {
  display: block;
  height: 100%;
  width: 100%;
}
.white-bg {
  background-color: #fff;
  padding: 20px;
}
.box-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.login-form {
  font-size: 16px;
  font-weight: 400;
  min-height: 200px;
  overflow: hidden;
  background: #fff;
  border-radius: 2px;
  min-width: 420px;
  max-width: 500px;
  padding: 20px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.login-form input {
  font-family: "nunito_sansregular";
}
.login-form h2 {
  margin-top: 0;
}
.form-group {
  margin-bottom: 15px;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.has-error .form-control {
  border-color: #c87872;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.form_submit_btn {
  background: #de1245;
  display: block;
  padding: 14px 0;
  border: 0;
  transition: background 400ms cubic-bezier(0.895, 0.03, 0.685, 0.22),
    color 0.2s ease 0.35s;
  width: 100%;
  color: white;
  line-height: 18px;
  font-size: 15px;
  font-size: 1rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  cursor: pointer;
}
.form_submit_btn:hover {
  background: rgba(222, 18, 69, 0.85);
}
.layout-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
}

.width-100
{
  width: 100%;
}

body{
  background-color: #f3f3f3 !important;
}
main {
  margin: 85px auto 0;
  height: calc(100% - 85px);
}

.actions-list {
  padding: 20px;
  border-radius: 2px;
}

.actions-list .list {
  border: 1px #dedede solid;
  border-bottom-width: 5px;
  border-radius: 3px;
  padding: 15px;
  width: 80%;
  margin: 0 auto 15px;
}

.actions-list .list:last-child {
  margin-bottom: 0;
}

.actions-list .list p {
  margin: 0;
}

.dashboard-nav {
  list-style: none;
  margin: 9px 0 0;
  padding: 0;
  float: left;
}
.dashboard-nav li {
  display: inline;
}
.dashboard-nav li:first-child {
  margin-left: 4px;
}
.dashboard-nav li.active a {
  background-color: #de1245;
  color: #fff;
}

.dashboard-nav li a {
  display: inline-block;
  padding: 6px 15px 6px 15px;
  color: #fff;
  border-radius: 2px;
}
.dashboard-nav li:not(.active) a:hover {
  color: #de1245;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  border: 1px #cbd6e2 solid;
  text-align: left;
  font-size: 14px;
}
.table thead {
  background: #cddae2;
}
.table thead th {
  color: #495661;
  font-weight: normal;
}
.table td {
  color: #8fa0ab;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f2f5f7;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #cbd6e2;
}
.table .fa-spin {
  color: #f99919;
  margin-left: 5px;
}
.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  float: right;
  margin: 0.2% 1% 0 1%;
}
.browse-btn-wrapper {
  position: relative;
  overflow: hidden;
  float: right;
}

.browse-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  min-width: 100px;
}
.message-info {
  font-size: 14px;
  opacity: 0.5;
}
.message-info-imp {
  font-size: 14px;
  float: left;
 color: red;
}
.table-controls {
  margin: 10px 0;
  margin-bottom: 25px;
}
.table-controls .btn {
  float: right;
  margin-top: 2px;
  margin-left: 20px;
}

.filter-panel .table-controls .btn {
  margin-top: 25px !important;
}
.table-controls .datepicker-control {
  float: right;
  margin-left: 15px;
}

.table-controls .records-type-option {
  float: left;
  margin-left: 8px;
  margin-top: 27px;
}

.table-controls .refresh-button {
  float: left;
  margin-left: 10px;
  margin-top: 32px;
}

.table-controls .search-member-textbox,.search-clubid-textbox,.search-button-box {
  float: left;
  margin-left: 10px;
  margin-top: 25px;
  display: -webkit-inline-flex;
  display: inline-flex
}

.bd-example .pagination {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.pagination {
  display: -webkit-flex;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 0.8rem;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #de1245;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #282828;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}


/* -------- Custom RC-pgination css  ----------------*/

.rc-pagination-item-active {
  background-color: #de1245 !important;
  border-color: #de1245 !important;
}

.rc-pagination-item:hover {
  border-color: #de1245 !important;
}

.rc-pagination-item:hover a {
  color: #de1245 !important;
}
.rc-pagination-item-active:hover a {
  color: #fff !important;
}

.rc-pagination-jump-prev:hover:after,
.rc-pagination-jump-next:hover:after {
  color: #de1245 !important;
}

.rc-pagination-jump-prev-custom-icon:hover .custom-icon-jump-prev,
.rc-pagination-jump-next-custom-icon:hover .custom-icon-jump-prev,
.rc-pagination-jump-prev-custom-icon:hover .custom-icon-jump-next,
.rc-pagination-jump-next-custom-icon:hover .custom-icon-jump-next {
  opacity: 1;
  color: #de1245 !important;
}

.rc-pagination-options-quick-jumper input:hover {
  border-color: #de1245 !important;
}

.rc-pagination-options-quick-jumper button:hover,
.rc-pagination-options-quick-jumper button:active,
.rc-pagination-options-quick-jumper button:focus {
  color: #de1245 !important;
  background-color: #fff;
  border-color:#de1245 !important;
}

.rc-pagination-simple .rc-pagination-simple-pager input:hover {
  border-color:#de1245 !important;
}

.rc-pagination-simple .rc-pagination-simple-pager button:hover,
.rc-pagination-simple .rc-pagination-simple-pager button:active,
.rc-pagination-simple .rc-pagination-simple-pager button:focus {
  color: #de1245 !important;
  background-color: #fff;
  border-color: #de1245 !important;
}

.rc-pagination-jump-prev:hover:after {
  content: "\AB";
}
.rc-pagination-jump-next:hover:after {
  content: "\BB";
}

.rc-pagination-prev a:after {
  content: "\2039" !important;
  display: block;
  color: #de1245 !important;
  padding: 2%;
}
.rc-pagination-next a:after {
  content: "\203A" !important;
  display: block;
  color: #de1245 !important;
  padding: 2%;
}

/*--------------404 Error ----------*/
.error-404{

}
.text-center{
  text-align: center;
}
.error-404-message-title {
  font-size: -webkit-xxx-large;
  color: #ff4040;
}
.go-home-404{
  padding: 2%;
    border-style: solid;
    color: #ff4040 !important;
  
}
.error-404 a:hover {
  color: black !important;
}


/*---------------Refresh Records-------------------*/

.refresh-image{
  width: 20px;
}

.refresh-anchor{
  cursor: pointer;
}

/*-------------- Member search filter  -------------*/

.search-member-input,.search-clubid-input{
  font-family: "nunito_sansregular";
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  font-size: smaller !important;
  float: left;
}

.search-member-textbox input[type=text]:focus, textarea:focus {
  box-shadow: 0 0 5px #FF8C00;
  border: 1px solid #FF8C00;
}
.search-clubid-textbox input[type=text]:focus, textarea:focus {
  box-shadow: 0 0 5px #FF8C00;
  border: 1px solid #FF8C00;
}

.search-button-ds{
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 2px;
  color: #fff;
  background-color: #f99919;
  transition: all 0.3s;
}

.search-member-button{
  float: left;
  margin-top: 5px;
  z-index: 999;
  cursor: pointer;
  padding: 5%;
    width: 50%;
  
}

.reset-member-button,.reset-clubid-button{
  float: left;
  margin-top: 5px;
  z-index: 999;
  cursor: pointer;
  padding: 5%;
    width: 50%;
  
}

.search-image{
  width: 18px;
}

.reset_search-image{
  width: 18px;
}

.member-search-buttons,.clubid-search-buttons{
  margin-left: -50px;
  z-index: 999;
}
.ds-clubid-search-buttons,.ds-member-search-buttons{
  margin-left: -25px;
  z-index: 999;
}

/*-------------- filter panel-----------------*/
.filter-panel{
  /* padding: 2%; */
  /* border-radius: 2px;
  border: darkorange;
  border-color: darkorange;
  border-style: dashed;
  border-width: thin; */
  margin-bottom: 1%;
}

.digi-sign.filter-panel{
  overflow-y: auto;
}

.datepicker-control-from{
  float: left;
    margin-left: 10px;

}

.datepicker-control-to{
  float: right;
    margin-left: 10px;

}


/*---------Media Queries Starts here---*/
@media (max-width: 767px) {
  .login-form {
    min-width: auto;
    max-width: 90%;
    position: static;
    -webkit-transform: none;
            transform: none;
  }
  .logo-wrapper {
    padding-right: 0;
    width: 80px;
  }
  .page-heading {
    font-size: 1.3rem;
  }
  .logout-btn {
    display: none;
  }
  .visible-xs {
    display: block !important;
  }
  header .dashboard-nav {
    margin-top: 0;
    position: fixed;
    z-index: 1;
    background: rgba(35, 35, 35, 0.9);
    transition: all 0.5s ease;
    left: 100%;
    right: 0;
    bottom: auto;
    top: 56px;
    overflow-y: auto;
    white-space: nowrap;
  }
  header .dashboard-nav.extend {
    left: 0;
  }
  header .dashboard-nav {
    width: 100%;
  }
  header .dashboard-nav li a {
    display: block;
  }
  header .dashboard-nav li {
    display: block;
    float: none;
    width: 100%;
    text-align: left;
  }
  header .dashboard-nav > li:hover,
  header .dashboard-nav > li.active {
    background: transparent;
  }
  header .dashboard-nav li.last {
    padding-left: 8px;
  }
  .toggle-menu {
    display: block;
    float: right;
    padding: 12px 8px 0 8px;
    background-color: #de1245;
    cursor: pointer;
    position: absolute;
    width: 42px;
    height: 40px;
    right: 0;
    top: 8px;
  }
  .toggle-menu span {
    display: block;
    background: #fff;
    height: 2px;
    margin-bottom: 5px;
    width: 26px;
    position: relative;
    transition: 0.5s ease;
  }

  .toggle-menu span.close-menu:nth-child(1) {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 20px;
    position: absolute;
  }
  .toggle-menu span.close-menu:nth-child(2) {
    display: none;
  }
  .toggle-menu span.close-menu:nth-child(3) {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    top: 20px;
    position: absolute;
  }
}
@media (max-width: 768px) {
  .logo-wrapper {
    padding-right: 0;
    width: 115px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
  .container-for-digital-signature{
    max-width: 85%;
  }
}



input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}

input[type=date]::-webkit-clear-button {
    -webkit-appearance: none;
    display: none;
}

.resultsDD {
    padding: 3px;
    border: solid orange 1.5px;
    font-family: 'nunito_sansregular';
    font-size: 15px;
}

.resultsDD option {
    padding: 3px;
}

.fileName{
    display: inline-block;
    margin-right: 7px;
    top: 2px;
    padding: 2px;
    border: 3px solid #d9cfcb
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #a94442;
   /* color: #737373; */
    font-size: 14px;
  }
  .has-error .checkbox,
  .has-error .checkbox-inline,
  .has-error .control-label,
  .has-error .help-block,
  .has-error .radio,
  .has-error .radio-inline,
  .has-error.checkbox label,
  .has-error.checkbox-inline label,
  .has-error.radio label,
  .has-error.radio-inline label {
    color: #a94442;
  }
  .login-server-error {
    text-align: center;
    color: #a94442;
    padding: 0;
    font-size: 20px;
  }

  
.login-form {
    font-size: 16px;
    font-weight: 400;
    min-height: 200px;
    overflow: hidden;
    background: #fff;
    border-radius: 2px;
    min-width: 420px;
    max-width: 500px;
    padding: 20px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .login-form input {
    font-family: "nunito_sansregular";
  }
  .login-form h2 {
    margin-top: 0;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .has-error .form-control {
    border-color: #c87872;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }
  .form_submit_btn {
    background: #de1245;
    display: block;
    padding: 14px 0;
    border: 0;
    transition: background 400ms cubic-bezier(0.895, 0.03, 0.685, 0.22),
      color 0.2s ease 0.35s;
    width: 100%;
    color: white;
    line-height: 18px;
    font-size: 15px;
    font-size: 1rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    cursor: pointer;
  }
  .form_submit_btn:hover {
    background: rgba(222, 18, 69, 0.85);
  }

  .form_reset_btn {
    background: #de1245;
    display: block;
    padding: 14px 0;
    border: 0;
    transition: background 400ms cubic-bezier(0.895, 0.03, 0.685, 0.22),
      color 0.2s ease 0.35s;
    width: 50%;
    color: white;
    line-height: 18px;
    font-size: 15px;
    font-size: 1rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    cursor: pointer;
  }
  .form_reset_btn:hover {
    background: rgba(222, 18, 69, 0.85);
  }
.credit-posting-files-refresh{
      width: 0%;
    float: right;
    margin-right: 3%;
    margin-top: 8px;

}

.invoice-creation-files-refresh{
      width: 0%;
    float: right;
    margin-right: 3%;
    margin-top: 8px;
}

