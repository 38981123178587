
.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #a94442;
   /* color: #737373; */
    font-size: 14px;
  }
  .has-error .checkbox,
  .has-error .checkbox-inline,
  .has-error .control-label,
  .has-error .help-block,
  .has-error .radio,
  .has-error .radio-inline,
  .has-error.checkbox label,
  .has-error.checkbox-inline label,
  .has-error.radio label,
  .has-error.radio-inline label {
    color: #a94442;
  }
  .login-server-error {
    text-align: center;
    color: #a94442;
    padding: 0;
    font-size: 20px;
  }

  
.login-form {
    font-size: 16px;
    font-weight: 400;
    min-height: 200px;
    overflow: hidden;
    background: #fff;
    border-radius: 2px;
    min-width: 420px;
    max-width: 500px;
    padding: 20px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  .login-form input {
    font-family: "nunito_sansregular";
  }
  .login-form h2 {
    margin-top: 0;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .has-error .form-control {
    border-color: #c87872;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }
  .form_submit_btn {
    background: #de1245;
    display: block;
    padding: 14px 0;
    border: 0;
    transition: background 400ms cubic-bezier(0.895, 0.03, 0.685, 0.22),
      color 0.2s ease 0.35s;
    width: 100%;
    color: white;
    line-height: 18px;
    font-size: 15px;
    font-size: 1rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    cursor: pointer;
  }
  .form_submit_btn:hover {
    background: rgba(222, 18, 69, 0.85);
  }

  .form_reset_btn {
    background: #de1245;
    display: block;
    padding: 14px 0;
    border: 0;
    transition: background 400ms cubic-bezier(0.895, 0.03, 0.685, 0.22),
      color 0.2s ease 0.35s;
    width: 50%;
    color: white;
    line-height: 18px;
    font-size: 15px;
    font-size: 1rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    cursor: pointer;
  }
  .form_reset_btn:hover {
    background: rgba(222, 18, 69, 0.85);
  }